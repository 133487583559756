import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Frase } from '../_class/frase';

@Injectable({
  providedIn: 'root'
})
export class FraseService {

  resource = `${environment.endpoint}/frase`

  constructor(private http: HttpClient) { }

  GetAll(params?) {
    return this.http.get<Frase[]>(this.resource, { params, observe: "response" });
  }
}
