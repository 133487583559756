<nav class="navbar navbar-expand-lg navbar-dark bg-primary d-flex">
    <a class="navbar-brand" [routerLink]="[]">Qdate <small>for Qualitat</small></a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed=
    !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapse">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" id="navbarNav" [ngbCollapse]="!isCollapsed">
        <ul class="navbar-nav">
            <li class="nav-item dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle" [routerLink]="[]" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" ngbDropdownToggle> 
                    Adminsitración
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
                    <a class="dropdown-item" routerLink="/config">Personalizar</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" [routerLink]="[]">Cerrar Sesión</a>
                </div>
            </li>
        </ul>
    </div>
</nav>