<div class="card" (click)="changeFrase()">
    <blockquote class="blockquote mb-0 card-body">
        <h5 class="card-title">{{current?.text}}</h5>
        <p class="text-muted">{{current?.description}}</p>
        <footer class="blockquote-footer  text-right" *ngIf="current?.author">
            <small class="text-muted">
                Author <cite title="Source Title">{{current.author}}</cite>
            </small>
        </footer>
    </blockquote>
</div>