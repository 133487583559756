import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor() { }
  sortedComponent = [
    { index: 4, component: 'Frase' },
  ].sort((a, b) => a.index - b.index)

  ngOnInit() {

  }

}
