import { Component, OnInit } from '@angular/core';
import { FraseService } from 'src/app/_services/frase.service';
import { Frase } from 'src/app/_class/frase';
import * as _ from "lodash";
@Component({
  selector: 'app-frase',
  templateUrl: './frase.component.html',
  styleUrls: ['./frase.component.scss']
})
export class FraseComponent implements OnInit {

  frases: Frase[]
  current: Frase
  routineUpdateFrase;
  time=1000*3600;
  constructor(private fraseService: FraseService) { }

  async ngOnInit() {
    var { headers, body } = await this.fraseService.GetAll().toPromise();
    this.frases = body;
    this.changeFrase();
    this.routineUpdateFrase = setInterval(()=>{
      this.changeFrase()
    },this.time);
  }

  changeFrase()
  {
    this.current = _.sample(this.frases);
  }

}
